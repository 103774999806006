jQuery(document).ready(function ($) {
  $('#content1').addClass('display_content');
  $('#tabs__header-btn-wrapper1').addClass('checked');
  $('#tab1').click(function (event) {
    $('.tabs__header-btn-wrapper').removeClass('checked');
    $('#tabs__header-btn-wrapper1').addClass('checked');
    $('.tabs__tab-item-wrapper').removeClass('display_content');
    $('#content1').addClass('display_content');
  });
  $('#tab2').click(function (event) {
    $('.tabs__header-btn-wrapper').removeClass('checked');
    $('#tabs__header-btn-wrapper2').addClass('checked');
    $('.tabs__tab-item-wrapper').removeClass('display_content');
    $('#content2').addClass('display_content');
  });
  $('#tab3').click(function (event) {
    $('.tabs__header-btn-wrapper').removeClass('checked');
    $('#tabs__header-btn-wrapper3').addClass('checked');
    $('.tabs__tab-item-wrapper').removeClass('display_content');
    $('#content3').addClass('display_content');
  });
});
