jQuery(function ($) {
  $('#filterPosts').submit(function () {
    var filter = $('#filterPosts');
    $.ajax({
      url: filter.attr('action'),
      data: filter.serialize(), // form data
      type: filter.attr('method'), // POST
      action: 'postfilter',

      success: function (data) {
        $('#loader').html(data); // insert data
      },
    });
    return false;
  });
  //process the form on radio button selection
  $('#filterPosts input[type=radio]').on('change', function () {
    $(this).closest('form').submit();
  });

  $('#filterPosts').on('change', function () {
    $(this).closest('form').submit();
  });

  //style the checked btns
  $('.posts-with-filter__button-wrapper').click(function (event) {
    $('.posts-with-filter__button-wrapper').removeClass('checked');
    $(this).addClass('checked');
  });
});
