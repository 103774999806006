$(document).ready(function () {
  $('#testimonial__wrapper').slick({
    slidesToShow: 1,
    arrows: true,
    dots: true,
    autoplay: true,
    respondTo: 'slider',
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          infinite: true,
          dots: true,
          autoplay: true,
        },
      },
    ],
  });
});
